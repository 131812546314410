<template>
  <div class="body">
    <div class="body-content">
      <div class="row-name">{{ subjectId.appTestVo.name }}</div>
      <div class="line"></div>
      <div class="practicing">
        <div v-if="subjectInfo.type === 1" class="practicing-type">单选题</div>
        <div v-if="subjectInfo.type === 2" class="practicing-type">多选题</div>
        <div v-else-if="subjectInfo.type === 3" class="practicing-type">
          简答题
        </div>
        <div class="practicing-content">
          <div class="practicing-title">
            <span>{{ subjectInfo.number }}、</span
            ><span v-html="subjectInfo.title"></span>
          </div>
          <!-- 单选题、多选题 -->
          <div v-if="subjectInfo.type == 1 || subjectInfo.type == 2">
            <div
              class="practicing-option"
              v-for="(item, index) in subjectInfo.topicOptions"
              :key="index"
              :class="[
                subjectInfo.testResultVo &&
                subjectInfo.testResultVo.answer.includes(item.opt)
                  ? 'select'
                  : subjectInfo.testResultVo &&
                    subjectInfo.testResultVo.yourAnswer.includes(item.opt)
                  ? 'error2'
                  : 'no-select',
              ]"
            >
              <span v-if="!item.isClick" style="margin-right: 10px">{{
                item.opt
              }}</span>
              <img
                v-else-if="item.isClick"
                src="../../assets/true.png"
                width="24px"
                height="24px"
              />
              <span v-html="item.value"></span>
            </div>
          </div>
          <div v-else style="margin-top: 10px">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入内容"
              v-model="subjectInfo.testResultVo.yourAnswer"
              :disabled="true"
            >
            </el-input>
          </div>
          <div class="practicing-answer" v-if="subjectInfo.type !== 3">
            <div class="practicing-answer-text">正确答案</div>
            <div
              class="practicing-answer-option"
              v-html="subjectInfo.testResultVo.answer"
            ></div>
          </div>

          <div class="practicing-analysis">
            <span class="analytical-title">{{ "本题解析" }}</span>
            <!-- <p v-if="subjectInfo.type===3" v-html="subjectInfo.testResultVo.answer"></p> -->
            <p
              v-if="subjectInfo.testResultVo.analysis !== ''"
              v-html="subjectInfo.testResultVo.analysis"
            ></p>
            <p v-else>无</p>
            <!-- <span class="analytical-title">{{subjectInfo.type===3 ? '本题答案' : '本题解析'}}</span>
            <p v-if="subjectInfo.type===3" v-html="subjectInfo.testResultVo.answer"></p>
            <p v-else v-html="subjectInfo.testResultVo.analysis"></p> -->
          </div>
        </div>
        <div class="practicing-time">
          <div class="practicing-time-clock">
            <img src="../../assets/clock.png" width="36px" height="36px" />
            <span class="practicing-time-clock-text">{{ useTime }}</span>
          </div>
          <div class="practicing-time-option">
            <div v-if="subject.topics != undefined" class="option-number">
              共 {{ subject.topics ? subject.topics.length : 0 }} 道题
            </div>
            <div class="option-line"></div>
            <ul class="option-options">
              <div
                v-for="(item, index) in questionNumber"
                :key="index"
                :class="[
                  'option-option',
                  item.correct == 0 ? 'error' : 'isSelect',
                  'cursor',
                ]"
                :style="{
                  backgroundColor: item.id == questionNumberId ? '#438EFF' : '',
                  color: item.id == questionNumberId ? 'white' : 'black',
                }"
                @click="goToNewQuestion(item)"
              >
                {{ item.num + 1 }}
              </div>
            </ul>
            <div class="option-bottom">
              <el-pagination
                small
                layout="prev, pager, next"
                :page-size="20"
                :current-page.sync="pagesu"
                :total="subject.topics != undefined ? subject.topics.length : 0"
                class="option-bottom-page"
                @current-change="currentChange"
              >
              </el-pagination>
              <div class="option-bottom-submit"></div>
            </div>
          </div>
          <el-button class="back" type="primary" round @click="back"
            >返回题库</el-button
          >
        </div>
      </div>
      <div class="body-button">
        <div class="body-button-content">
          <el-button
            type="primary"
            class="body-button-button"
            @click="lastQuestion"
            >上一题</el-button
          >
          <div class="body-button-center">
            <img
              src="../../assets/stars-2.png"
              width="24px"
              height="24px"
              class="body-button-center-img"
              @click="collection()"
              v-show="showCollection"
            />
            <div
              class="body-button-center-text cursor"
              @click="collection()"
              v-show="showCollection"
            >
              收藏
            </div>
          </div>
          <el-button
            v-if="showNextQuestion"
            type="primary"
            class="body-button-button"
            @click="nextQuestion(nextQuestionValue)"
            >{{ nextQuestionValue }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getTestInfo,
  submitTest,
  addTopicCollect,
  testAnalysis,
  getRecordInfo,
} from "@/api/course";
var _this;
var intt;
export default {
  data() {
    return {
      count: 20,
      title: "",
      useTime: "00:00:00", // 用时
      nextQuestionValue: "下一题",
      showNextQuestion: true, // 是否显示提交按钮
      practice: {}, // 上一个界面传送过来的数据
      subjectId: {
        appTestVo: {
          name: "",
        },
      },
      subject: {}, // 请求得到的题目
      subjectInfo: {
        testResultVo: {
          answer: "",
        },
      }, // 一道题目的信息
      subjectIndex: 0, // 做到第几道题目的索引
      last: 0, // 最后一道题的序号
      textareaValue: "",
      oldSelectIndex: "", // 上一次的选择
      showPopup: false, // 是否弹出目录弹窗
      chooseList: [], //做完题目的ID集合
      showAnalysis: false,
      analysisInfo: {},
      showCollection: false,
      viewResolution: false,
      question: [], // 当前题目页的20道题
      questionNumber: [], // 当前题目页的20道题序号
      minute1: true,
      questionNumberId: 0,
      pagesu: 1,
    };
  },
  mounted() {
    _this = this;
    _this.getTestResult(_this.$route.query.id);
    _this.showCollection = true;
  },
  methods: {
    /**
     * 获取测试的结果
     */
    getTestResult(id) {
      getRecordInfo({ id: id }).then((res) => {
        res.data.appTestVo.topics.forEach(function(item, index) {
          res.data.appTestVo.topics[index] = Object.assign(
            res.data.appTestVo.topics[index],
            { number: index + 1 }
          );
        });
        _this.subjectId = res.data;
        _this.getTime(res.data.useTime);
        _this.getAnswerDetail(res.data);
      });
    },
    /**
     * 得到做题花费的时间
     */
    getTime(time) {
      var xiaoshi = parseInt(time / 3600);
      var fen = parseInt((time - xiaoshi * 60 * 60) / 60);
      var miao = parseInt(time - xiaoshi * 60 * 60 - fen * 60);
      if (xiaoshi < 10) {
        xiaoshi = "0" + xiaoshi;
      }
      if (fen < 10) {
        fen = "0" + fen;
      }
      if (miao < 10) {
        miao = "0" + miao;
      }
      _this.useTime = xiaoshi + ":" + fen + ":" + miao;
    },
    /**
     * 点击了下一题
     */
    nextQuestion(event) {
      _this.subjectIndex = Number(_this.subjectIndex) + 1;

      //分页变动
      var num = _this.subjectIndex / 20;
      if (num % 1 === 0) {
        this.pagesu++;
        this.currentChange(this.pagesu);
      }
      _this.subjectInfo = _this.subject.topics[_this.subjectIndex];
      if (_this.subjectInfo.type === 3) {
        _this.textareaValue = _this.subjectInfo.testResultVo.yourAnswer;
      }
      if (_this.subject.topics[_this.subjectIndex + 1] == undefined) {
        _this.showNextQuestion = false;
      }
      _this.questionNumberId = _this.subjectInfo.id;
    },
    /**
     * 点击了上一题
     */
    lastQuestion: function() {
      _this.showNextQuestion = true; // 是否显示提交按钮
      _this.nextQuestionValue = "下一题";
      if (_this.subjectIndex != 0) {
        _this.subjectIndex = _this.subjectIndex - 1;
        //触发题目分页变动
        var num = (_this.subjectIndex + 1) / 20;
        if (num % 1 === 0) {
          this.pagesu--;
          this.currentChange(this.pagesu);
        }
        _this.subjectInfo = _this.subject.topics[_this.subjectIndex];
        _this.textareaValue =
          _this.subject.topics[_this.subjectIndex].briefAnswer;
      }
      _this.questionNumberId = _this.subjectInfo.id;
    },
    /**
     * 点击收藏
     */
    collection() {
      var answer, correct, topicId;
      if (_this.isAnalysis) {
        answer = _this.analysisInfo.yourAnswer;
        correct = _this.analysisInfo.correct;
        topicId = _this.analysisInfo.topicId;
      } else {
        answer = _this.subjectInfo.testResultVo.yourAnswer;
        correct = _this.subjectInfo.testResultVo.correct;
        topicId = _this.subjectInfo.testResultVo.topicId;
      }
      addTopicCollect({
        yourAnswer: answer,
        correct: correct,
        topicId: topicId,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("收藏成功");
        }
      });
    },
    /**
     * 获得答案详情
     */
    getAnswerDetail(test) {
      _this.questionNumber = [];
      test.appTestVo.topics.forEach(function(item, index) {
        // 记录多选题未选、正确、错误答案
        if (item.type === 3) {
          item.testResultVo.answer = item.testResultVo.answer.replace(
            /\n/g,
            "<br>"
          );
        }
        // 右边的题目数量
        if (index < 20) {
          _this.questionNumber.push({
            id: item.id,
            num: index,
            correct: item.testResultVo.correct,
          });
        }
        _this.questionNumberId = _this.questionNumber[0].id;
        if (item.type === 2) {
          item.topicOptions.forEach(function(item1, index1) {
            var resu = {
              // 0：未选择，1：答案正确，2：答案错误
              result: 0,
            };
            item.topicOptions[index1] = Object.assign(item1, resu);
            var youAnswer = item.testResultVo.yourAnswer.split("、");
            youAnswer.forEach(function(item2, index2) {
              if (item1.opt == item2) {
                item.topicOptions[index1].result = 2;
              }
            });
            var answer = item.testResultVo.answer.split(",");

            answer.forEach(function(item2, index2) {
              if (item1.opt == item2) {
                item.topicOptions[index1].result = 1;
              }
            });
          });
          test.appTestVo.topics[index] = item;
        }
      });
      _this.subject = test.appTestVo;
      if (test.index !== undefined) {
        _this.subjectIndex = test.index;
        test.appTestVo.topics.forEach((item) => {
          item.title = item.title.replace(/\n/g, "<br>");
          if (item.topicOptions) {
            item.topicOptions.forEach((item1) => {
              item1.value = item1.value.replace(/\n/g, "<br>");
            });
          }
        });
        _this.subjectInfo = test.appTestVo.topics[_this.subjectIndex];
        if (test.index == _this.subject.topics.length - 1) {
          _this.showNextQuestion = false;
        }
      } else {
        test.appTestVo.topics.forEach((item) => {
          item.title = item.title.replace(/\n/g, "<br>");
          if (item.topicOptions) {
            item.topicOptions.forEach((item1) => {
              item1.value = item1.value.replace(/\n/g, "<br>");
            });
          }
        });
        _this.subjectInfo = test.appTestVo.topics[_this.subjectIndex];
      }
    },
    currentChange(page) {
      _this.questionNumber = [];
      var endPage = page * 20;
      var startPage = endPage - 20;
      var topi = _this.subjectId.appTestVo.topics;
      for (var i = 0; i < topi.length; i++) {
        if (i >= startPage && i < endPage) {
          _this.questionNumber.push({
            id: topi[i].id,
            num: i,
            correct: topi[i].testResultVo.correct,
          });
        }
      }
    },
    onClickLeft() {
      _this.$router.go(-1);
    },
    /**
     * 判断是否是整数
     */
    isInteger(obj) {
      return parseInt(obj, 10) == obj;
    },
    // 预览题目图片
    previewImg(src) {
      ImagePreview([src]);
    },
    // 点击目录跳转到对应题目
    goToNewQuestion(item) {
      var index = item.num;
      _this.questionNumberId = item.id;
      this.subjectIndex = index;
      this.subjectInfo = this.subject.topics[this.subjectIndex];
    },
    back() {
      this.$router.push({
        path: "selectClass",
        query: {
          id: localStorage.getItem("selectClassId"),
          subjectId: localStorage.getItem("subjectId"),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  .body-content {
    margin-left: 68px;
    .row-name {
      font-size: 20px;
    }
    .line {
      height: 5px;
      width: 60px;
      margin: 4px 0 30px 0;
      background-color: #4394ff;
      border-radius: 5px;
    }
    .practicing {
      margin-top: 6px;
      display: -webkit-inline-box;
      font-weight: 400;
      margin-bottom: 50px;
      .practicing-type {
        font-size: 12px;
        color: #fff;
        background-color: #4394ff;
        width: 46px;
        height: 21px;
        border-radius: 11px;
        line-height: 21px;
        text-align: center;
        margin-right: 10px;
      }
      .practicing-content {
        width: 655px;
        font-size: 20px;
        color: #000000;
        margin-right: 80px;
        .practicing-option {
          margin-top: 20px;
          display: flex;
        }
        .view-analysis {
          font-size: 18px;
          font-weight: 400;
          color: #4394ff;
          margin-top: 50px;
          display: flex;
          align-items: center;
        }
        .practicing-answer {
          margin-top: 40px;
          font-weight: 400;
          display: flex;
          .practicing-answer-text {
            font-size: 18px;
            color: #000000;
            line-height: 28px;
            flex: 1;
          }
          .practicing-answer-option {
            font-size: 26px;
            color: #4394ff;
            flex: 6;
          }
        }
        .practicing-analysis {
          font-size: 18px;
          font-weight: 400;
          line-height: 30px;
          color: #000000;
          margin-top: 15px;
        }
      }
      .practicing-time {
        width: 315px;
        height: 368px;
        .practicing-time-clock {
          width: 100%;
          height: 86px;
          background: #ffffff;
          border: 1px solid #c9c9c9;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          .practicing-time-clock-text {
            font-size: 30px;
            font-weight: 400;
            color: #4394ff;
            margin-left: 3px;
          }
        }
        .practicing-time-option {
          margin-top: 10px;
          height: 318px;
          background: #ffffff;
          border: 1px solid #c9c9c9;
          border-radius: 5px;
          padding: 15px 20px 0 20px;
          position: relative;
          .option-number {
            font-size: 20px;
            font-weight: 400;
            color: #000000;
            margin-bottom: 15px;
          }
          .option-line {
            height: 1px;
            background-color: #dcdfe6;
          }
          .option-bottom {
            position: absolute;
            bottom: 0;
            width: 265px;
            .option-bottom-page {
              text-align: center;
              margin-top: 30px;
            }
            .option-bottom-submit {
              margin: 20px auto;
              font-size: 18px;
              font-weight: 400;
              color: #4394ff;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .option-result-page {
    text-align: center;
    margin-top: 20px;
  }
  .body-button {
    height: 100px;
    width: 730px;
    .body-button-content {
      display: flex;
      flex: 3;
      .body-button-button {
        width: 96px;
      }
      .body-button-center {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 400;
        line-height: 33px;
        .body-button-center-text {
          color: #4394ff;
          line-height: 24px;
          margin-left: 5px;
        }
        .body-button-center-img {
          align-items: center;
        }
      }
    }
  }
}
.cursor {
  cursor: pointer;
}
.dialog-options {
  width: 480px;
  height: 95px;
  margin: 0 auto;
  .dialog-option {
    margin-top: 20px;
  }
}
.isSelect {
  background-color: #b4d2ff;
  color: #438eff;
}
.notSelect {
  background-color: white;
  color: #535353;
}
.option-options {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  .option-option {
    width: 35px;
    height: 35px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    text-align: center;
    line-height: 35px;
    margin: 15px 8px 0 8px;
  }
}
.select {
  color: #4394ff;
}

.no-select {
  color: black;
}
.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 640px;
  height: 700px;

  .circular1 {
    position: absolute;
    left: 126px;
    top: 62px;
  }
  .circular2 {
    position: absolute;
    top: 162px;
    left: 135px;
  }
  .circular3 {
    position: absolute;
    top: 67px;
    right: 190px;
  }
  .circular4 {
    position: absolute;
    right: 150px;
  }
  .circular5 {
    position: absolute;
    top: 218px;
    right: 125px;
  }
  .center-img {
    width: 190px;
    height: 190px;
    color: #f2f2f2;
    background-image: url("../../assets/achievement.png");
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .center-img-content {
      height: 45px;
      font-size: 32px;
      font-weight: bold;
      color: #ffffff;
    }
    .center-img-text {
      font-size: 13px;
      font-weight: bold;
      color: #fff;
      text-align: center;
    }
  }
}
.dialog-center {
  width: 470px;
  height: 145px;
  margin: 0 auto;
  .circle-title {
    text-align: center;
    margin-top: 20px;
    height: 28px;
    font-size: 20px;
    font-weight: 400;
    color: #acacac;
  }
  .circle-statistics {
    margin-top: 32px;
    display: flex;
    flex: 5;
    .circle-statistics-left {
      flex: 2;
      text-align: center;
      margin-top: 10px;
    }
    .circle-statistics-center {
      width: 1px;
      height: 70px;
      background-color: #ebebeb;
    }
    .circle-statistics-right {
      flex: 2;
      text-align: center;
      margin-top: 10px;
    }
  }
}
.circle-statistics-title {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
}
.circle-statistics-text {
  font-size: 14px;
  font-weight: 400;
  color: #acacac;
  margin-top: 10px;
}
.divider {
  width: 471px;
  height: 1px;
  margin-top: 20px;
  background-color: #ebebeb;
}
.view-all-analysis {
  width: 84px;
  height: 20px;
  margin: 60px auto 40px auto;
  font-size: 14px;
  font-weight: 400;
  color: #4394ff;
}
.error {
  color: #ff6060;
  background-color: #ffcece;
}
.error2 {
  color: #ff6060;
}
.back {
  width: 295px;
  margin: 10px;
}
.analytical-title {
  padding-left: 15px;
  border-left: 3px solid #4394ff;
}
</style>
