var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body"},[_c('div',{staticClass:"body-content"},[_c('div',{staticClass:"row-name"},[_vm._v(_vm._s(_vm.subjectId.appTestVo.name))]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"practicing"},[(_vm.subjectInfo.type === 1)?_c('div',{staticClass:"practicing-type"},[_vm._v("单选题")]):_vm._e(),(_vm.subjectInfo.type === 2)?_c('div',{staticClass:"practicing-type"},[_vm._v("多选题")]):(_vm.subjectInfo.type === 3)?_c('div',{staticClass:"practicing-type"},[_vm._v(" 简答题 ")]):_vm._e(),_c('div',{staticClass:"practicing-content"},[_c('div',{staticClass:"practicing-title"},[_c('span',[_vm._v(_vm._s(_vm.subjectInfo.number)+"、")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.subjectInfo.title)}})]),(_vm.subjectInfo.type == 1 || _vm.subjectInfo.type == 2)?_c('div',_vm._l((_vm.subjectInfo.topicOptions),function(item,index){return _c('div',{key:index,staticClass:"practicing-option",class:[
              _vm.subjectInfo.testResultVo &&
              _vm.subjectInfo.testResultVo.answer.includes(item.opt)
                ? 'select'
                : _vm.subjectInfo.testResultVo &&
                  _vm.subjectInfo.testResultVo.yourAnswer.includes(item.opt)
                ? 'error2'
                : 'no-select' ]},[(!item.isClick)?_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(item.opt))]):(item.isClick)?_c('img',{attrs:{"src":require("../../assets/true.png"),"width":"24px","height":"24px"}}):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(item.value)}})])}),0):_c('div',{staticStyle:{"margin-top":"10px"}},[_c('el-input',{attrs:{"type":"textarea","rows":5,"placeholder":"请输入内容","disabled":true},model:{value:(_vm.subjectInfo.testResultVo.yourAnswer),callback:function ($$v) {_vm.$set(_vm.subjectInfo.testResultVo, "yourAnswer", $$v)},expression:"subjectInfo.testResultVo.yourAnswer"}})],1),(_vm.subjectInfo.type !== 3)?_c('div',{staticClass:"practicing-answer"},[_c('div',{staticClass:"practicing-answer-text"},[_vm._v("正确答案")]),_c('div',{staticClass:"practicing-answer-option",domProps:{"innerHTML":_vm._s(_vm.subjectInfo.testResultVo.answer)}})]):_vm._e(),_c('div',{staticClass:"practicing-analysis"},[_c('span',{staticClass:"analytical-title"},[_vm._v(_vm._s("本题解析"))]),(_vm.subjectInfo.testResultVo.analysis !== '')?_c('p',{domProps:{"innerHTML":_vm._s(_vm.subjectInfo.testResultVo.analysis)}}):_c('p',[_vm._v("无")])])]),_c('div',{staticClass:"practicing-time"},[_c('div',{staticClass:"practicing-time-clock"},[_c('img',{attrs:{"src":require("../../assets/clock.png"),"width":"36px","height":"36px"}}),_c('span',{staticClass:"practicing-time-clock-text"},[_vm._v(_vm._s(_vm.useTime))])]),_c('div',{staticClass:"practicing-time-option"},[(_vm.subject.topics != undefined)?_c('div',{staticClass:"option-number"},[_vm._v(" 共 "+_vm._s(_vm.subject.topics ? _vm.subject.topics.length : 0)+" 道题 ")]):_vm._e(),_c('div',{staticClass:"option-line"}),_c('ul',{staticClass:"option-options"},_vm._l((_vm.questionNumber),function(item,index){return _c('div',{key:index,class:[
                'option-option',
                item.correct == 0 ? 'error' : 'isSelect',
                'cursor' ],style:({
                backgroundColor: item.id == _vm.questionNumberId ? '#438EFF' : '',
                color: item.id == _vm.questionNumberId ? 'white' : 'black',
              }),on:{"click":function($event){return _vm.goToNewQuestion(item)}}},[_vm._v(" "+_vm._s(item.num + 1)+" ")])}),0),_c('div',{staticClass:"option-bottom"},[_c('el-pagination',{staticClass:"option-bottom-page",attrs:{"small":"","layout":"prev, pager, next","page-size":20,"current-page":_vm.pagesu,"total":_vm.subject.topics != undefined ? _vm.subject.topics.length : 0},on:{"update:currentPage":function($event){_vm.pagesu=$event},"update:current-page":function($event){_vm.pagesu=$event},"current-change":_vm.currentChange}}),_c('div',{staticClass:"option-bottom-submit"})],1)]),_c('el-button',{staticClass:"back",attrs:{"type":"primary","round":""},on:{"click":_vm.back}},[_vm._v("返回题库")])],1)]),_c('div',{staticClass:"body-button"},[_c('div',{staticClass:"body-button-content"},[_c('el-button',{staticClass:"body-button-button",attrs:{"type":"primary"},on:{"click":_vm.lastQuestion}},[_vm._v("上一题")]),_c('div',{staticClass:"body-button-center"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCollection),expression:"showCollection"}],staticClass:"body-button-center-img",attrs:{"src":require("../../assets/stars-2.png"),"width":"24px","height":"24px"},on:{"click":function($event){return _vm.collection()}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCollection),expression:"showCollection"}],staticClass:"body-button-center-text cursor",on:{"click":function($event){return _vm.collection()}}},[_vm._v(" 收藏 ")])]),(_vm.showNextQuestion)?_c('el-button',{staticClass:"body-button-button",attrs:{"type":"primary"},on:{"click":function($event){return _vm.nextQuestion(_vm.nextQuestionValue)}}},[_vm._v(_vm._s(_vm.nextQuestionValue))]):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }